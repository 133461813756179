.App {
  min-height: 100vh;
  display: flex;
  align-content: center;
  justify-content: center;
}

.App-logo {
  /* width: 40vmin; */
  cursor: pointer;
}
